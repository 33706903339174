#root {
  height: 100%;
}

body {
  height: 100%;
  font-size: .875rem;
  background-color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
  }
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}


.video-list-container {
  margin-top: 100px;
}
.btn {
  color: #fff !important;
}
.list-row {
   margin-top:20px;
}

.form-wrapper {
  margin-top:100px;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*
 * Public Video
 */

 .public-form button {
  background: #F8E34C;
  color: black !important;
  border-radius: 3px;
  font-size: 500;
  border:none !important;
 }
 .public-form button:hover {
   background: #E7CF25;
 }
 .form-signin button:disabled {
   opacity: 0.5;
  }

  .form-signin label {   
   color: #8A8A8A;
 }
 
 .public-form .image {
   text-align: center;
   margin-bottom: 20px;;
 }
 .public-form .image img {
   width: 60%;   
 }

 .public-form h4 {
   font-size: 16px;
 }


.public-main {
  padding-top: 20px !important;
}

.public-main h1 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 15px 0;
}
.public-main p {
  font-size: 1rem;
  line-height: 1.8rem;
  color : #6D6D6D;
  font-weight: 400;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}
.logo-wrapper {
  padding:20px !important;
}

.footer {
  background-color: #f5f5f5;
  text-align: center;
}

.footer span {
  color : #373737;
  /* margin-bottom:5px */
}
.footer a {
  /* font-size: 16px; */
  color: #000;
  z-index: 99999;
}